import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import moment from "moment";

import Layout from "../components/layout";
import archiveStyle from "../styles/archive.module.scss";
import SEO from "../components/seo";

const Archives = (props) => {
    const data = useStaticQuery(graphql`
        query ArchiveQuery {
            allMarkdownRemark(
                sort: { order: DESC, fields: frontmatter___date }
                filter: { frontmatter: { skip_archive: { ne: true } } }
            ) {
                edges {
                    node {
                        frontmatter {
                            title
                            date
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);

    // group by years
    let posts = {};
    if (data) {
        data.allMarkdownRemark.edges.forEach((edge, i) => {
            let year = moment(edge.node.frontmatter.date).year();
            if (posts[year]) {
                posts[year].push(edge.node);
            } else {
                posts[year] = [edge.node];
            }
        });
    }

    return (
        <Layout>
            <div className={archiveStyle.root}>
                {Object.keys(posts)
                    .sort()
                    .reverse()
                    .map((key, i) => (
                        <div className={archiveStyle.posts} key={key}>
                            <div className={archiveStyle.year}>
                                <h3>{key}</h3>
                            </div>
                            {posts[key].map((post, j) => (
                                <div key={j} className={archiveStyle.post}>
                                    <small>
                                        {moment(post.frontmatter.date).format(
                                            "MM-DD"
                                        )}
                                    </small>
                                    <Link to={`/archives/${post.fields.slug}`}>
                                        <p>{post.frontmatter.title}</p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    ))}
            </div>
            <SEO title="過去ログ" />
        </Layout>
    );
};

export default Archives;
